import { css } from '@emotion/react'
import { Avatar, Button, MenuLayout, TemporaryNotification, Text, useScreenVariant } from '@orus.eu/pharaoh'
import { memo, useCallback, useEffect, useState, type ReactNode } from 'react'
import { useNavigateBack } from '../../lib/hooks/use-navigate-back'
import { usePermissions } from '../../lib/use-permissions'
import { useWindowLocationHash } from '../../lib/use-window-location-hash'
import { Page } from './page'

export type MenuPageProps = {
  /**
   * Always displayed
   */
  menuTitle: ReactNode
  /**
   * Displayed when the menu is open (always open on desktop)
   */
  menuBody: ReactNode
  /**
   * Content of the page
   */
  pageContent: ReactNode
  /**
   * Optional borders to apply to the menu
   */
  border?: string
}

export const MenuPage = memo<MenuPageProps>(function MenuPage({
  menuTitle,
  menuBody,
  pageContent,
  border,
}: MenuPageProps) {
  const hash = useWindowLocationHash()
  const open = hash === 'menu'
  const isBackoffice = useScreenVariant() === 'backoffice'
  const { type: userType } = usePermissions()
  const isPartner = userType === 'partner'

  const [isNewBoLayoutAlertVisible, setIsNewBoLayoutAlertVisible] = useState<boolean>(true)

  useEffect(() => {
    const data = window.sessionStorage.getItem('IS_NEW_BO_LAYOUT_ALERT_DISMISSED')
    if (data !== null && data !== undefined) {
      setIsNewBoLayoutAlertVisible(false)
    }
  }, [])

  useEffect(() => {
    if (isNewBoLayoutAlertVisible !== false) return

    window.sessionStorage.setItem('IS_NEW_BO_LAYOUT_ALERT_DISMISSED', JSON.stringify(true))
  }, [isNewBoLayoutAlertVisible])

  return (
    <Page>
      {isBackoffice && isPartner && isNewBoLayoutAlertVisible ? (
        <TemporaryNotification
          variant="info"
          onExitClick={() => setIsNewBoLayoutAlertVisible(false)}
          css={css`
            flex-grow: 1;
            position: absolute;
            width: 100%;
          `}
        >
          <Text variant="body2">
            Beta - Vous avez accès à une version en développement de la plateforme. Vos remarques aident à améliorer le
            produit final. Contactez François sur son email{' '}
            <a href="mailto:francois.coste@orus.eu">francois.coste@orus.eu</a> pour toute remarque
          </Text>
        </TemporaryNotification>
      ) : null}
      <MenuLayout
        menuTitle={menuTitle}
        openMenuButton={<OpenMenuButton />}
        closeMenuButton={<CloseMenuButton />}
        menuBody={menuBody}
        pageContent={pageContent}
        menuOpen={open}
        border={border}
      />
    </Page>
  )
})

const OpenMenuButton = memo(function OpenMenuButton() {
  const handleClick = useCallback(() => {
    window.location.hash = 'menu'
  }, [])

  return <Button onClick={handleClick} size="large" variant="text" avatar={<Avatar icon="bars-solid" size="40" />} />
})

const CloseMenuButton = memo(function CloseMenuButton() {
  const navigateBack = useNavigateBack()

  return <Button onClick={navigateBack} size="large" variant="text" avatar={<Avatar icon="xmark-solid" size="40" />} />
})
